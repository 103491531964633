<template>
	<div class="min-h-screen grid grid-cols-2 lg:items-center page-container">
		<div class="mr-24 relative hidden lg:block">
			<img
				src="/web/images/auth/login.jpg"
				alt="Login Banner"
				class="banner-img aspect-square object-cover border-radial-1-card/60"
			/>
			<div
				class="light-circle bg-red-700 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
			></div>
			<div class="absolute h-1/4 bottom-0 backdrop-blur-md text-center rounded-bl-[60px]">
				<h1 class="font-bold text-3xl mt-8">
					{{ $t('loginPage.bannerTitle') }}
				</h1>
				<p class="font-medium text-lg w-3/4 mx-auto">
					{{ $t('loginPage.bannerDescription') }}
				</p>
			</div>
		</div>
		<div class="col-span-2 lg:col-span-1">
			<div class="flex justify-center items-center mt-8">
				<img src="https://cdn.realitystudio.ai/assets/web/logo.svg" class="w-[200px]" alt="RealityStudio" />
			</div>
			<AuthLoginForm />
		</div>
	</div>
</template>

<script setup lang="ts">
definePageMeta({
	middleware: ['authorize-guest'],
	layout: 'desktop-version',
})

useHead({
	title: `RealityStudio - Login`,
	meta: [
		{ name: 'robots', content: 'index, follow' },
		{
			name: 'description',
			content: 'Start creating amazing avatars with RealityStudio!',
		},
		{
			property: 'og:title',
			content: `RealityStudio - Login`,
		},
		{
			property: 'og:description',
			content: 'Start creating amazing avatars with RealityStudio!',
		},
	],
})
</script>

<style scoped>
.light-circle {
	@apply w-[300px] h-[70vh] blur-[200px];
}

.banner-img {
	max-height: 80vh;
	border-bottom-left-radius: 60px;
	border-width: 6px;
}
</style>
