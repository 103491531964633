<template>
	<div class="px-4 flex flex-col gap-4 pt-12">
		<AuthGoogleLogin :redirectTo="redirectTo" />
		<div class="flex items-center gap-2">
			<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
			<span class="text-sm leading-5 font-normal w-fit">
				{{ $t('loginPage.or') }}
			</span>
			<div class="flex-1 flex bg-gray-900 h-px rounded-xl"></div>
		</div>
		<form @submit.prevent="onSubmit">
			<DefaultInput
				class="mb-4"
				id="email"
				v-model="email"
				:label="$t('loginPage.email')"
				:placeholder="$t('loginPage.emailPlaceholder')"
				type="email"
				required
			/>
			<DefaultPasswordInput
				:id="'password'"
				:label="$t('loginPage.password')"
				:placeholder="$t('loginPage.passwordPlaceholder')"
				class="mb-2"
				@update:passwordValue="password = $event"
				required
			/>
			<NuxtLink
				@click="$captureEvent('login_goto_forgot_password')"
				class="text-base leading-4 font-bold mb-6 flex w-fit ml-auto"
				to="forget-password"
			>
				{{ $t('loginPage.forgotPassword') }}
			</NuxtLink>
			<DefaultHCaptcha
				ref="hCaptcha"
				class="mb-6"
				@onVerify="token => (captchaToken.value = token)"
				v-if="platform === 'web'"
			/>
			<DefaultButton type="submit" :isLoading="isLoading.value">
				{{ $t('loginPage.login') }}
			</DefaultButton>
		</form>
		<div class="text-lg leading-7 w-fit mx-auto">
			{{ $t('loginPage.noAccount') }}
			<NuxtLink class="font-bold" to="create-account">
				{{ $t('loginPage.createAccount') }}
			</NuxtLink>
		</div>
	</div>
</template>

<script setup lang="ts">
import type { IUser } from '~/types/models/user.interface'

const { isMobile } = useDevice()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const email = ref<string>('')
const password = ref<string>('')
const captchaToken = ref<string>('')
const isLoading = ref<boolean>(false)

const platform = $getPlatform()
const redirectTo = computed(() => route.query?.redirectTo as string)
const { t } = $i18n()
const onSubmit = async () => {
	isLoading.value = true

	try {
		const user = await $api('/auth/login', {
			method: 'POST',
			body: {
				email: email.value,
				password: password.value,
				token: captchaToken.value,
				platform: platform.value,
			},
		})

		userStore.login(user as IUser)
		$captureEvent('login_submit_success')

		if (redirectTo.value) {
			router.push(redirectTo.value)
			return
		}

		router.push('/')
		const nuxtApp: any = useNuxtApp()
		nuxtApp.$requestPushNotifications()
	} catch (error) {
		if (platform.value === 'web') {
			;(refs.hCaptcha as any).reset()
		}

		$sentryCaptureException(error)
		$captureEvent('login_submit_error')

		$toast({
			type: 'error',
			title: t('common.toastTitle.error'),
			message: t('loginPage.loginError'),
		})
	}

	isLoading.value = false
}
</script>
